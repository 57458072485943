@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono&family=Courier+Prime:wght@400;700&display=swap');

:root {
  --wm-color-grey: #cccccc;
  --wm-color-separators: #7a7a7a;
  --wm-color-sidebar: #444;
  --wm-size-header: 48px;
  --wm-font-header: "Montserrat";
  --wm-font-body: "Montserrat";
  --wm-min-width: "850px";
  --wm-font-monospace: "Azeret Mono";
}

html,
body {
  font-family: var(--wm-font-body);
  background-color: #f1f1f1;
}

a {
  font-style: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#root {
  min-width: var(--wm-min-width);
}

.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator {
  display: none !important;
}

div[style*="z-index: 0"] {
  display: none;
}