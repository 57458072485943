.reactMarkDown > *  {
    margin-top: 0;
}

.reactMarkDown ul {
    margin-top: 1em;
    margin-bottom: 1em;
    list-style: disc outside none;
}

.reactMarkDown ul li {
    margin-left: 2em;
    display: list-item;
    text-align: -webkit-match-parent;
}

.reactMarkDown table {
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid black;
}

.reactMarkDown td,
.reactMarkDown th {
    padding: 5px;
    border: 1px solid black;
}

.reactMarkDown th {
    background-color: #ccc;
}

.reactMarkDown code {
    display: block;
    border: 2px solid #ccc;
    padding: 10px 10px 10px 10px;
    background-color: #eee;
}

.reactMarkDown blockquote {
    border-left: 3px solid #999;
    padding: 10px 0px 10px 10px;
    margin-left: 30px;
}

.reactMarkDown blockquote > p {
    margin: 0
}
